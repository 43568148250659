import { Section, Timeline } from '@in/component-library';
import React, { FC } from 'react';
import useSWR from 'swr';
import { getCourseEnrollments } from '../../apis/get-courses';
import { ApplicationCaseProcess } from '../../models/statuspage/application-case-process';
import { ApplicationCaseSteps } from '../../models/statuspage/application-case-steps';
import { ApplicationStatus } from '../../models/statuspage/application-status';
import { FormStatusPage } from '../../models/statuspage/form-status-page';
import { Recommendation } from '../../models/statuspage/recommendation';
import Loader from '../loader/loader';
import RecommendationCourseWrapper from '../recommendations/recommendation-wrapper';

interface CaseProcessProps {
  caseProcessData: ApplicationCaseProcess | undefined;
  applicationData: FormStatusPage | undefined;
  recommendations?: Recommendation[];
}

const CaseProcess: FC<CaseProcessProps> = ({ caseProcessData, applicationData, recommendations }) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  const [activeStep, setActiveStep] = React.useState<number | undefined>(-1);

  const { data: enrollments } = useSWR(['api/courses/enrollments'], getCourseEnrollments);

  React.useEffect(() => {
    const caseStep = caseProcessData?.steps
      ?.map((caseProcessStatus: ApplicationCaseSteps) => caseProcessStatus.status)
      .lastIndexOf(applicationData?.application.statusType ? applicationData.application.statusType : '');
    setActiveStep(caseStep);
  }, [caseProcessData?.steps, applicationData?.application.statusType]);

  const draftedApplicationTimelineDate = (status: string, date: Date) => {
    const timelineDate: Date = new Date(date);
    return status === ApplicationStatus.Draft ? timelineDate.toLocaleDateString('no-NO', options) : '';
  };

  const handleTimelineStepDates = (caseProcessStatus: string, date: Date) => {
    const timelineDate = new Date(date);
    if (applicationData?.application.statusType === ApplicationStatus.Draft) return draftedApplicationTimelineDate(caseProcessStatus, date);

    return caseProcessStatus === ApplicationStatus.Draft || caseProcessStatus === ApplicationStatus.Submitted
      ? timelineDate.toLocaleDateString('no-No', options)
      : `est ${timelineDate.toLocaleDateString('no-No', options)}`;
  };

  return (
    <div className="timeline">
      {caseProcessData?.steps && caseProcessData.steps.length > 0 ? (
        <Timeline
          data={caseProcessData.steps.map((step, index) => ({
            key: index,
            header: step.title,
            date: handleTimelineStepDates(step.status, step.date),
            children: (
              <div className="timeline__children">
                <p className="timeline__step-description">{step.description}</p>
                {step.status === ApplicationStatus.Submitted &&
                  recommendations?.map((recommendation, index) => (
                    <RecommendationCourseWrapper
                      key={`recommendation-${index}`}
                      caseProcessData={caseProcessData}
                      course={recommendation}
                      existingEnrollment={enrollments?.filter((e) => e.id === recommendation.identifier)[0]}
                      headingLevel="h3"
                    />
                  ))}
              </div>
            ),
          }))}
          currentStep={activeStep}
          headingLevel={2}
        />
      ) : (
        <Section bleed>
          <Loader />
        </Section>
      )}
    </div>
  );
};

export default CaseProcess;
